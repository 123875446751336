@import url('https://fonts.googleapis.com/css2?family=Permanent_Merker:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Satisfy&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  
}

body{ 
  background-color: rgb(229, 227, 242);
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  margin: auto;
  height: 100vh;
}

.inputBar{
  background-color: rgb(186, 73, 73);
  height: 70px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  box-shadow:  rgba(100, 100, 111, 0.2) 0px 7px 0px 14px;

}

.components{
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  margin: auto;
  padding: 0px 2rem;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  max-width: 500px;
}

.inputChat{
  border: none;
  border-radius: 8px;
  height: 45px;
  box-sizing: border-box;
  padding: 8px 12px;
  font-size: 18px;
  color: rgb(51, 51, 51);
  background-color: white;
  max-width: 400px;
  width: 100%;
  margin-left: 15px;
  font-family: 'Poppins', sans-serif;
}

.content{
  font-family: 'Poppins', sans-serif;
  position: relative;
  box-sizing: border-box;
  max-height: 850px;
  background-color: rgb(229, 227, 242);
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.sidebar{
  color: white;
  font-family: 'Satisfy', cursive;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 50px;
  background-color: rgb(133, 46, 46);
  z-index: 1;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 30px;
  box-shadow:  rgba(100, 100, 111, 0.2) 0px 7px;
}



::-webkit-scrollbar {
  display: none;
}

.logoChavez{
  width: 90px;
  height: 100px;
  margin: 8px;
}

.titleDisclaimer{
  color: rgb(156, 83, 83);
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 1px;
}

.content-disclaimer{
  font-size: 13px;
  text-align: center;
  margin: 20px;
  max-width: 600px;
  color: rgb(119, 119, 119);
}


.sendButton{
  background-color: rgb(78, 22, 22);
  border-radius: 50px;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  height: 50px;
  width: 50px;
  border: 0px;
  margin: 10px;
  color: azure;
  cursor: pointer;
}

.sendButton:hover{
  background-color: rgb(235, 114, 114);
}

.respuesta{
  background-color: rgb(223, 115, 115);
  color: white;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  max-width: 400px;
  font-size: 18px;
  margin-left: 40px;
  font-family: 'Poppins', sans-serif;
}

.miMensaje{
  background-color: rgb(122, 17, 17);
  color: white;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  margin-right: 20px ;
  max-width: 400px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
}

.link{
  color: brown;
  letter-spacing: 2px;
}